const Status = {
  created: "Registered",
  registered: "Registered",
  approved: "Approved",
  rejected: "Rejected",
  FindingTrips: "Online",
  Online: "Online",
  Offline: "Offline",
  true: "Yes",
  false: "No",
  yes: "Yes",
  no: "No",

  OnPoolTrip: "On Pool Trip",
  OnTrip: "On Trip",
  DestinationInroute: "At Destination",
  PickupInroute: "In Route",
  PickupArrived: "Pickup Arrived",
  approved: "Approved",
  created: "New",
  rejected: "Rejected",
  active: "Active",
  inactive: "In Active",
  Cancelled: "Cancelled",
  Completed: "Completed",
  Scheduled: "Scheduled",
  FindingDrivers: "Finding",
  PickupInroute :"Riding",
  WatingForDriver: "Waiting",
  Scheduled: "Scheduled",
  0: "Sun",
  1: "Mon",
  2: "Tus",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};
export default Status;
