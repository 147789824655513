import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";

import Dashboard from "./routes/dashboard";
import Customers from "./routes/customers";
import Drivers from "./routes/drivers";
import CancelDrivers from "./routes/canceldrivers";
import CancelCustomer from "./routes/cancelcustomers";
import Dispatchers from "./routes/dispatchers";
import CarTypes from "./routes/car-types";
import Trips from "./routes/trips";
import PromoCodes from "./routes/promo-codes";
import AdminUser from "./routes/users";
import PushNotification from "./routes/notification";
import Faq from "./routes/faq";
import ContentPage from "./routes/contentPages";
import Settings from "./routes/settings";

import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";

import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import AdditionalCharges from "./routes/additionalCharges";
import Contract from "./routes/contract";

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/dashboard`} component={Dashboard} />
                <Route path={`${match.url}/drivers`} component={Drivers} />
                <Route
                  path={`${match.url}/canceldrivers`}
                  component={CancelDrivers}
                />
                <Route
                  path={`${match.url}/cancelcustomers`}
                  component={CancelCustomer}
                />
                {/* <Route
                  path={`${match.url}/cancelcustomers12`}
                  component={CancelCustomers}
                /> */}
                <Route path={`${match.url}/customers`} component={Customers} />
                <Route
                  path={`${match.url}/dispatchers`}
                  component={Dispatchers}
                />
                <Route path={`${match.url}/trips`} component={Trips} />
                <Route path={`${match.url}/car-types`} component={CarTypes} />
                <Route
                  path={`${match.url}/promo-codes`}
                  component={PromoCodes}
                />
                <Route
                  path={`${match.url}/notification`}
                  component={PushNotification}
                />
                <Route
                  path={`${match.url}/contentPages`}
                  component={ContentPage}
                />
                <Route path={`${match.url}/faq`} component={Faq} />
                {/*
                 */}
                <Route path={`${match.url}/users`} component={AdminUser} />
                <Route path={`${match.url}/settings`} component={Settings} />
                <Route path={`${match.url}/additional-charges`} component={AdditionalCharges} />
                <Route path={`${match.url}/contract`} component={Contract} />
                <Route
                  component={asyncComponent(() =>
                    import("app/routes/extraPages/routes/404")
                  )}
                />
              </Switch>
            </div>
            <Footer />
            <ToastContainer autoClose={2000} />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
