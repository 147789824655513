import React, { Component, useState, Fragment } from "react";
import moment from "moment";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {Loading} from "../index"

import { NavLink, withRouter } from "react-router-dom";

class DataTable extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <Table>{this.props.children}</Table>;
  }
}

const DataTableHead = props => {
  const { columns, sort, orderBy, style,type } = props;
  const updateProps = data => {
    if(props.sort)
      props.sort(data);
  };
  let rightColums=(type && type=="charges")?[]:["action","createdAt"]
  return (
    <TableHead>
      <TableRow>
        {columns.length > 0 &&
          columns.map(column => {
            return (
              <TableCell
                key={column.id}
                style={style}
                align={!rightColums.includes(column.id)?"left":"right"}
                padding={column.disablePadding ? "none" : "default"}
                onClick={() =>
                  updateProps({
                    key: column.id,
                    order: parseInt(column.order) === 1 ? -1 : 1
                  })
                }
              >
                {column.label}
                {orderBy === column.id &&
                  orderBy.toLowerCase() !== "action" && (
                    <TableSortLabel
                      active={true}
                      direction={column.order == 1 ? "asc" : "desc"}
                    />
                  )}
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
};
DataTableHead.defaultProps = {
 style:{}
}

class DataTableBody extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      loading,
      page,
      rowsPerPage,
      data,
      column,
      actions,
      actionBtn
    } = this.props;
    // console.log(column)
    let columnNames = column
      .map(key => {
        return key;
      })
      .filter(key => key != "_id" && key != "action");
    let notDisplay = ["_id", "action", "createdAt"];

    return (
      <TableBody>
        {loading === false &&
          data.length > 0 &&
          data.map((Dkey, index) => {
            return (
              <TableRow hover tabIndex={-1} key={Dkey._id}>
                <TableCell padding="none" align="left">
                  {++index + page * rowsPerPage}
                </TableCell>
                {column.map((key, index) => {
                  if (!notDisplay.includes(key.id)) {
                    if (key.extra !== undefined) {
                      return (
                        <TableCell padding="none" key={index} align="left">
                          <div className="user-profile d-flex flex-row">
                            <Avatar
                              alt={Dkey["name"]}
                              src={
                                Dkey[key.extra] != "none"
                                  ? Dkey[key.extra]
                                  : require("assets/images/placeholder.jpg")
                              }
                              className="ml-3 user-avatar"
                            />
                            <div className="user-detail">
                              <h5 className="user-name">{Dkey[key.id]} </h5>
                            </div>
                          </div>
                        </TableCell>
                         
                     
                     );
                    } else {
                      if (key.date !== undefined) {
                        return (
                          <TableCell padding="none" align="left" key={index}>
                            {moment(Dkey[key.id]).format(key.date)}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell padding="none" align="left" key={index}>
                            {(Array.isArray(Dkey[key.id]) || key.numeric)
                              ? ((Dkey[key.id] && Dkey[key.id].length > 0) ? Dkey[key.id].length : 0)
                              : Dkey[key.id] || "---"}
                          </TableCell>
                        );
                      }
                    }
                  }

                  if (key.id === "createdAt") {
                    return (
                      <TableCell padding="none" align="right" key={index}>
                        {moment(Dkey[key.id]).format("MM/DD/YYYY")}
                      </TableCell>
                    );
                  }
                })}
                {actions && (
                  <TableCell padding="none" align="right" key={index}>
                    <Action actions={actions} id={Dkey._id} />
                  </TableCell>
                )}
                {actionBtn && (
                  <TableCell padding="none" align="right" key={index}>
                    <NavLink to={`${actionBtn.link}${Dkey._id}`}>
                      <Button className="bg-amber text-white CBmargin">
                        <i className={`zmdi ${actionBtn.icon}`} />{" "}
                        {actionBtn.title}
                      </Button>
                    </NavLink>
                  </TableCell>
                )}
              </TableRow>
            );
          })}

        {loading === false && data.length === 0 && (
          <TableRow>
            <TableCell align="center" colSpan={column.length}>
              No data Found!
            </TableCell>
          </TableRow>
        )}
        {loading === true && (
          <TableRow>
            <TableCell align="center" colSpan={column.length}>
              <Loading />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  }
}

class DataTablePagination extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      count,
      page,
      rowsPerPage,
      rowsPerPageOptions,
      onChangePage
    } = this.props;
    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </TableRow>
      </TableFooter>
    );
  }
}

class Action extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      anchorEl: undefined,
      menuState: false
    };

    this.onOptionMenuSelect = this.onOptionMenuSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  onOptionMenuSelect = event => {
    this.setState({ menuState: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ menuState: false });
  };

  render() {
    const { id, actions } = this.props;
    const { menuState, anchorEl } = this.state;

    return (
      <Fragment>
        <IconButton key={id} onClick={e => this.onOptionMenuSelect(e)}>
          <i className="zmdi zmdi-more-vert" />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={menuState}
          onClose={e => this.handleRequestClose()}
          MenuListProps={{
            style: {
              width: 150,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
        >
          {actions &&
            actions.map((key, index) => {
              if (key["label"] === "Delete") {
                return (
                  <MenuItem
                    key={key["label"]}
                    onClick={e => this.handleRequestClose()}
                  >
                    <ListItemIcon>
                      <DeleteForeverIcon fontSize="small" />
                    </ListItemIcon>
                    <a href="javascript:void(0)" onClick={() => key.link(id)}>
                      Delete
                    </a>
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    key={key["label"]}
                    onClick={e => this.handleRequestClose()}
                  >
                    <ListItemIcon>
                      {
                        key['icon'] !=="" && key['icon']
                      }
                      {key["label"] === "View" && (
                        <VisibilityIcon fontSize="small" />
                      )}
                      {key["label"] === "Edit" && <EditIcon fontSize="small" />}
                      {key["label"] === "Payment" && (
                        <MonetizationOnIcon fontSize="small" />
                      )}
                    </ListItemIcon>
                    <NavLink to={`${key.link}/${id}`}>{key["label"]}</NavLink>
                  </MenuItem>
                );
              }
            })}
        </Menu>
      </Fragment>
    );
  }
}

const ActionLink=(props)=>{
  const {index,actionBtn, id}= props
  return(
    <TableCell padding="none" align="right" key={index}>
    <NavLink to={`${actionBtn.link}${id}`}>
      <Button className="bg-amber text-white CBmargin">
        <i className={`zmdi ${actionBtn.icon}`} />{" "}
        {actionBtn.title}
      </Button>
    </NavLink>
  </TableCell>
  )
}
const ActionPopup=(props)=>{
  const {key,action,id, title,icon}= props
  return(
    <TableCell padding="none" align="right" key={key}>
     
      <Button className="bg-amber text-white CBmargin" onClick={()=>action(id)}>
        <i className={`zmdi ${icon}`} />{" "}
        {title}
      </Button>
  </TableCell>
  )
}
ActionPopup.defaultProps = {
  key:Math.random(),
  title:'',
  icon:'Zmdi-delete'
}
// DataTable= withRouter(DataTable)
// DataTableHead= withRouter(DataTableHead)
// DataTableBody= withRouter(DataTableBody)
// DataTablePagination= withRouter(DataTablePagination)
export { DataTable, DataTableHead, DataTableBody, DataTablePagination, Action,ActionPopup, TableBody, TableRow, TableCell, CircularProgress, moment };
